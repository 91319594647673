import React, { Component ,Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import './RealEstateList.css';

function RealEstateList(){
        const dispatch = useDispatch();
        const appUI = useSelector(state => state.appUI);
        let displayStart = 0;
        if(appUI.realEstateCurrentPage > 1){
            displayStart = (appUI.realEstateCurrentPage - 1) * 6;
        }
        const RealEstateList = appUI.realEstateList.slice(
            displayStart, 
            displayStart + 6
            );

        function renderList(){
            return RealEstateList.map((value , index) =>  {
                let category = value._embedded['wp:term'][0][0].name;
                let filteredTitle = value.title.rendered.replace('&#8211;', ''); 
                let imageExceptionBerkova = value._embedded['wp:featuredmedia'][0].source_url;

                if(value._embedded['wp:term'][0][0].name === 'Byt' && value._embedded['wp:term'][1].length){
                    if(value._embedded['wp:term'][1][0].name === 'K prodeji'){
                        category = 'Byt na prodej';
                    }
                    else{
                        category = 'Byt na pronájem';
                    }
                }
                let sold = null;
                console.log(value._embedded['wp:term'][3][0].name);
                if(value._embedded['wp:term'][3][0].name === 'PRODÁNO'){
                    sold = <h3 className="title text-center sold-text  shadow-lg fw-light sticky-top">Prodáno</h3>
                }
                else if(value._embedded['wp:term'][3][0].name === 'PRONAJATO'){
                    sold = <h3 className="title text-center sold-text  shadow-lg fw-light sticky-top">Pronajato</h3>
                }
                else if(value._embedded['wp:term'][3][0].name === 'REZERVOVÁNO'){
                    sold = <h3 className="title text-center sold-text  shadow-lg fw-light sticky-top">Rezervováno</h3>
                }
                else if(value._embedded['wp:term'][3][1].name != null ){
                    if(value._embedded['wp:term'][3][1].name === 'REZERVOVÁNO'){
                        sold = <h3 className="title text-center sold-text  shadow-lg fw-light sticky-top">Rezervováno</h3>
                    }
                    
                }
                else if(value._embedded['wp:term'][3][2].name != null ){
                    if(value._embedded['wp:term'][3][2].name === 'REZERVOVÁNO'){
                        sold = <h3 className="title text-center sold-text  shadow-lg fw-light sticky-top">Rezervováno</h3>
                    }
                    
                }
                if (appUI.realEstateAgentTexts.personal.name.includes("Berková") && filteredTitle === "Prodej bytu 2+1, 57 m², Semily – Podmoklice") {
                    imageExceptionBerkova = 'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/ExceptionRealEstateImageBerkova1.jpg';

                }
                return (
                    <>
                       <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    {sold}
                                    <a href={value.link}>
                                        {
                                            value._embedded.hasOwnProperty(['wp:featuredmedia']) ?
                                            <img  src={imageExceptionBerkova} className={`thumbnail property-image`} alt="Blog Images"/> :
                                            <div className="d-flex justify-content-center justify-items-center">Fotka nemovitosti nelze načíst.</div>
                                        }
                                       
                                    </a>
                                </div>
                                <div className="content">
                                    
                                    <p className="blogtype">{category}</p>
                                    <h4 className="title"><a href={value.link} target="_blank" >{filteredTitle}</a></h4>
                                    <div className="blog-btn">
                                        <a className="rn-btn btn-white text-white" href={value.link} target="_blank">Detail</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            });
        }
        return (
             <Fragment>
                 <div className="row">
                    {renderList()}
                 </div>
             </Fragment>
        );
}

export default RealEstateList;