import React, { useEffect } from 'react';
import { slickDot, slideSlick, portfolioSlick2 } from "../page-demo/script";
import { useSelector, useDispatch } from 'react-redux';
import ScrollToTop from '../component/common/ScrollTop';
import HeaderMain from "../component/header/HeaderMain";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import Testimonial from "../elements/Testimonial";
import ContactOne from "../elements/contact/ContactOne";
import RealEstateList from "../elements/realestate/RealEstateMainList";
import ServiceList from "../elements/service/ServiceList";
import Helmet from "../component/common/Helmet";
import Brand from "../elements/BrandTwo";
import ScrollAnimation from 'react-animate-on-scroll';
import ServiceModal from '../component/common/ServiceModal';
import { Link } from "react-router-dom";
import Loading from '../component/common/Loading';
import Slider from 'react-slick';
import LegalModal from '../component/common/LegalModal';
import { SET_ASSETS_LOADED, SET_SHOW_MODAL } from '../actions/types';
import './RealEstate.css';
import { useParams, useHistory } from 'react-router-dom';

const RealEstateBroker = () => {
    let { service } = useParams();
    let history = useHistory();
    useEffect(() => {
        if (service !== undefined) {
            if (service === "hypotecni_kalkulacka" || service === "oceneni_nemovitosti") {
                if (service === "hypotecni_kalkulacka")
                    dispatch({ type: SET_SHOW_MODAL, payload: { show: true, service: 'morgage_estimate' } })
                if (service === "oceneni_nemovitosti")
                    dispatch({ type: SET_SHOW_MODAL, payload: { show: true, service: 'real_estate_valuation' } })
            }
            else {
                history.push("/404")
            }
        }
    }, [])



    const appUI = useSelector(state => state.appUI);
    const dispatch = useDispatch();
    const textContent = appUI.realEstateAgentTexts.personal.homePage.text;
    const commonText = appUI.realEstateAgentTexts.common;
    const imgSRCs = appUI.realEstateAgentTexts.personal.homePage.img;
    let heroTitle = textContent.heroTitle;
    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    let heroTitleStyle = {};
    let categoryStyle = {};
    if (width <= 767) {
        heroTitle = textContent.heroTitleSM;
    }
    if (width <= 767 && textContent.heroTitle.length > 22) {
        heroTitleStyle = { fontSize: "20px", lineHeight: '35px', marginLeft: '-30px' };
    }
    if (width <= 767 && appUI.realEstateAgentTexts.personal.name.includes("Sven")) {
        heroTitleStyle = { fontSize: "20px", lineHeight: '35px', marginLeft: '-25px' };
        categoryStyle = { marginLeft: '-25px' };
    }
    if (appUI.realEstateAgentTexts.personal.name.includes("Berková") || appUI.realEstateAgentTexts.personal.name.includes("Spáčil")) {
        heroTitleStyle = { fontFamily: "Dancing Script", maxWidth: '70%', marginLeft: '-10px' };
        if (width <= 767) {
            heroTitleStyle = { ...heroTitleStyle, maxWidth: '85%', fontSize: '24px' };
        }
        else {
            heroTitleStyle = { ...heroTitleStyle, fontSize: '52px' };
        }
    }
    const SlideList = [
        {
            textPosition: 'text-left',
            category: textContent.heroCategory,
            title: heroTitle,
            description: '',
            buttonText: '',
            buttonLink: ''
        }
    ]

    let title = appUI.realEstateAgentTexts.personal.name.includes("Berková") ? '' : 'O mně',
        description = textContent.aboutDescription,
        quote = textContent.aboutQuote;


    function renderRealEstate() {
        if (!appUI.realEstateList.length && !appUI.realEstateList.realEstateLoadingError) {
            return (
                <>
                    <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
                        <div className="row">
                            <Loading message="Načítání nemovitostí.." />

                        </div>
                    </ScrollAnimation>
                    <div className="row">
                        <div className="col-lg-12">
                            <ScrollAnimation animateIn="fadeInUp" delay={300} duration={2} animateOnce={true}>
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                    <Link className="rn-button-style--2 btn-solid no-padding" to={"nemovitosti"}><span>Všechny Nemovitosti</span></Link>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </>
            )
        }
        else if (appUI.realEstateList.realEstateLoadingError) {
            return (
                <>

                    <div className="row d-flex justify-content-center text-center">
                        <h1 className="fw-light text-center">Došlo k chybě při načítání nemovitostí. Zkontrolujte prosím Vaše připojení k internetu.</h1>
                    </div>

                </>
            )
        }

        else {
            return (
                <>
                    <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
                        <div className="row">
                            <RealEstateList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                        </div>
                    </ScrollAnimation>
                    <div className="row">
                        <div className="col-lg-12">
                            <ScrollAnimation animateIn="fadeInUp" delay={300} duration={2} animateOnce={true}>
                                <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                    {appUI.realEstateList.length <= 3 ? null : <Link className="rn-button-style--2 btn-solid no-padding" to={"nemovitosti"}><span>Všechny Nemovitosti</span></Link>}
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </>
            )
        }
    }
    function renderTestimonialSection() {
        if (appUI.realEstateAgentTexts.personal.testimonials.length) {
            return (
                <div className="rn-testimonial-area bg_color--1 ptb--120" id="testimonial">
                    <ScrollAnimation animateIn="fadeInLeft" duration={2} animateOnce={true}>
                        <div className="container">
                            <Testimonial />
                        </div>
                    </ScrollAnimation>
                </div>
            );
        }
        else return null;
    }
    function renderNewsSection() {
        if (appUI.realEstateAgentTexts.personal.displayNews) {
            return (
                <div id="news" className="fix">
                    <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                        <div className="container">
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="section-title text-center">
                                        <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
                                            <h2>{commonText.homePage.newsAreaTitle}</h2>
                                            <p>{commonText.homePage.newsAreaSubtitle}</p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                            {renderNewsList()}
                        </div>
                    </div>
                </div>
            );
        }
        else return null;
    }
    function renderRealEstateSection() {
        if (appUI.realEstateAgentTexts.personal.displayRealEstate) {
            return (
                <div id="real-estate" className="fix">
                    <div className="portfolio-area ptb--120 bg_color--1">
                        <div className="portfolio-sacousel-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                            <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
                                                <h2 className="title">{commonText.homePage.realEstateAreaTitle}</h2>
                                                <p>{commonText.homePage.realEstateAreaSubtitle}</p>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                {renderRealEstate()}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else return null;
    }
    function renderNewsList() {
        if (appUI.realEstateAgentTexts.common.news.length || appUI.realEstateAgentTexts.personal.personalNews.length) {
            let newsArray = [];
            if (appUI.realEstateAgentTexts.common.news.length) {
                appUI.realEstateAgentTexts.common.news.forEach(element => {
                    newsArray.push(element);
                });
            }
            if (appUI.realEstateAgentTexts.personal.personalNews.length) {
                appUI.realEstateAgentTexts.personal.personalNews.forEach(element => {
                    newsArray.push(element);
                });
            }
            slickDot.slidesToShow = newsArray.length;
            return (
                <>
                    <ScrollAnimation animateIn="fadeInRight" delay={500} duration={3} animateOnce={true}>
                        <div className="rn-slick-dot">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {newsArray.map((value, index) => (
                                                <div className="portfolio" key={index}>
                                                    <div className="thumbnail-inner">
                                                        <div style={{ backgroundImage: `url(${value.imageSRC})` }} className={`thumbnail `}></div>
                                                        <div style={{ backgroundImage: `url(${value.imageSRC})` }} className={`bg-blr-image `}></div>
                                                    </div>
                                                    <div className="content">
                                                        <div className="inner">
                                                            <p>{value.category}</p>
                                                            <h4><a href="/portfolio-details">{value.title}</a></h4>
                                                            <div className="portfolio-button">
                                                                <Link className="rn-btn text-white" to={`clanky/${value.title}`}>Přěčíst</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </>
            )
        }

        else {
            return (
                <>
                    <div className="row">
                        <Loading message="Načítání novinek.." />
                    </div>
                </>
            )
        }
    }


    return (
        <div>
            <Helmet pageTitle="Osobní stránka" ogImage={appUI.realEstateAgentTexts.personal.ogImage} metaDescription={appUI.realEstateAgentTexts.personal.metaDescription} metaKeywords={appUI.realEstateAgentTexts.personal.metaKeywords} />
            <ServiceModal />
            <LegalModal />
            <HeaderMain homeLink="/" logo="symbol-dark" color="color-black" />
            {/* Start Slider Area   */}
            <div id="top" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value, index) => (
                        <div style={{ backgroundImage: `url(${imgSRCs.hero})` }} className={`slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image_home_page `} key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">

                                        <div className={`inner ${value.textPosition}`}>
                                            <ScrollAnimation animateIn="fadeInLeft" duration={2} animateOnce={true}>
                                                {value.category ? <span style={categoryStyle}>{value.category}</span> : ''}
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="fadeInLeft" delay={800} duration={4} animateOnce={true}>
                                                {value.title ? <h1 className="title" dangerouslySetInnerHTML={{ __html: value.title }} style={heroTitleStyle}></h1> : ''}
                                            </ScrollAnimation>
                                            {value.description ? <p className="description" dangerouslySetInnerHTML={{ __html: value.description }}></p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */}

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <ScrollAnimation animateIn="fadeInLeft" duration={2} animateOnce={true}>
                                            <img className="w-100" src={appUI.realEstateAgentTexts.personal.homePage.img.aboutMe} alt={appUI.realEstateAgentTexts.personal.homePage.img.logoAlt} />
                                        </ScrollAnimation>
                                    </div>
                                </div>

                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <ScrollAnimation animateIn="fadeInRight" duration={2} animateOnce={true}>
                                            <div className="section-title">

                                                <h2 className="title pt--25">{title}</h2>
                                                <p className="lead">{quote}</p>
                                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>

                                            </div>

                                            <div className="row mt--30">
                                                <TabTwo tabStyle="tab-style--1" />
                                            </div>

                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End About Area */}

            {/* Start Service Area  */}
            <div id="service" className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
                                        <h2 className="title">{commonText.homePage.serviceAreaTitle}</h2>
                                        <p>{commonText.homePage.serviceAreaSubtitle}</p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item={commonText.services.length} column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Area  */}

            {/* Start Real Estate Area */}
            {renderRealEstateSection()}
            {/* End Real Estate Area */}

            {/* Start News Area */}
            {renderNewsSection()}
            {/* End News Area */}

            {/* Start Testimonial Area */}
            {renderTestimonialSection()}
            {/* End Testimonial Area */}

            {/* Start Contact Area */}
            <div id="contact" className="fix">

                <div className="rn-contact-area ptb--120 bg_color--5">
                    <ContactOne />
                </div>
            </div>
            {/* End Contact Area */}

            {/* Start Brand Area  */}
            {appUI.realEstateAgentTexts.personal.name.includes("Zikmunda") ? null :
                < div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <Brand branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* End Brand Area  */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop />
            </div>
            {/* End Back To Top */}
        </div >
    )
}

export default RealEstateBroker;
