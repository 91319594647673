import React from "react";
import { useDispatch, useSelector } from 'react-redux';


function RealEstateMainList(props) {
    const { column, styevariation } = props;
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);
    const list = appUI.realEstateList.slice(0, 3);
    console.log(list);


    function renderList() {
        return list.map((value, index) => {
            let filteredTitle = value.title.rendered.replace('&#8211;', '');
            if (value._embedded.hasOwnProperty(['wp:featuredmedia'])) {
                let category = value._embedded['wp:term'][0][0].name;
                if (value._embedded['wp:term'][0][0].name === 'Byt') {
                    if (value._embedded['wp:term'][1][0].name === 'K prodeji') {
                        category = 'Byt na prodej';
                    }
                    else {
                        category = 'Byt na pronájem';
                    }
                }
                let sold = null;
                if (appUI.realEstateAgentTexts.personal.name.includes("Zikmunda")) {
                    if (value._embedded['wp:term'][3][0].name === 'PRODÁNO') {
                        sold = <h3 className="title text-center sold-text  shadow-lg fw-light sticky-top">Prodáno</h3>
                    }
                    else if (value._embedded['wp:term'][3][0].name === 'PRONAJATO') {
                        sold = <h3 className="title text-center sold-text  shadow-lg fw-light sticky-top">Pronajato</h3>
                    }
                    else if (value._embedded['wp:term'][3][0].name === 'REZERVOVÁNO') {
                        sold = <h3 className="title text-center sold-text  shadow-lg fw-light sticky-top">Rezervováno</h3>
                    }
                }
                if (appUI.realEstateAgentTexts.personal.name.includes("Zikmunda") && index === 0) {
                    return (
                        <div className={`${column}`} key={10}>
                            <div className={`portfolio ${styevariation}`}>
                                <div className="thumbnail-inner">

                                    <div style={{ backgroundImage: `url(https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/portfolio-04.png` }} className={`thumbnail `}></div>
                                    <div style={{ backgroundImage: `url(https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/portfolio-04.png` }} className={`bg-blr-image `}></div>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <p>Developerský projekt</p>
                                        <h4><a href="https://www.projekt-kubelikova.cz/" target="_blank">Tojdům Kubelíkova LBC</a></h4>
                                        <div className="portfolio-button">
                                            <a className="rn-btn" href="https://www.projekt-kubelikova.cz/" target="_blank">Web projektu</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                if (appUI.realEstateAgentTexts.personal.name.includes("Berková") && filteredTitle === "Prodej bytu 2+1, 57 m², Semily – Podmoklice") {
                    return (
                        <div className={`${column}`} key={10}>
                            <div className={`portfolio ${styevariation}`}>
                                <div className="thumbnail-inner">

                                    <div style={{ backgroundImage: `url(https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/ExceptionRealEstateImageBerkova1.jpg` }} className={`thumbnail `}></div>
                                    <div style={{ backgroundImage: `url(https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/ExceptionRealEstateImageBerkova1.jpg` }} className={`bg-blr-image `}></div>
                                </div>
                                <div className="content">
                                    <div className="inner">
                                        <p>{category}</p>
                                        <h4><a href="/portfolio-details">{filteredTitle}</a></h4>
                                        <div className="portfolio-button">
                                            <a className="rn-btn" href={value.link} target="_blank">Detail</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                return (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                {sold}
                                <div style={{ backgroundImage: `url(${value._embedded['wp:featuredmedia'][0].source_url})` }} className={`thumbnail `}></div>
                                <div style={{ backgroundImage: `url(${value._embedded['wp:featuredmedia'][0].source_url})` }} className={`bg-blr-image `}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{category}</p>
                                    <h4><a href="/portfolio-details">{filteredTitle}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.link} target="_blank">Detail</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else return null;

        });
    }
    return (
        <React.Fragment>
            {renderList()}
        </React.Fragment>
    )
}
export default RealEstateMainList;