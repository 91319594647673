// Lukáš Berka
// https://lukas-berka.cz/
let SiteContentBerka = {
    currentRealEstateAgent: 'SiteContentBerka',
    name: 'Lukáš Berka',
    type: 'PODNIKATEL A INVESTOR'
}

// Jakub Hanuš
// https://jakub-hanus.cz/
let SiteContentHanus = {
    currentRealEstateAgent: 'SiteContentBerka',
    name: 'Jakub Hanuš',
    type: 'REALITNÍ MAKLÉŘ'
  
}

// Lucie Berková
// https://lucie-berkova.cz/
// <meta name="google-site-verification" content="HfmDVfKezMJvig5em7dWw-4zT6Cdjc2u02PFz528YW4" />
// <meta name="description" content="Lucie Berková - Realitní makléř a investor." />
// <meta name="keywords" content="P4L-GROUP RK, P4L-RK, Lucie Berková, Berková, prodej nemovitosti, Jablonec, osobní web, realitní makléř, reality" />
let SiteContentBerkova = {
    currentRealEstateAgent: 'SiteContentBerkova',
    name: 'Lucie Berková',
    type: 'REALITNÍ MAKLÉŘ A INVESTOR'
}
// Sven Foitischek
// https://sven-foitischek.cz/
let SiteContentSven = {
    currentRealEstateAgent: 'SiteContentSven',
    name: 'Sven Foitischek',
    type: 'REALITNÍ MAKLÉŘ'
}
// Tomáš Zikmunda
// https://tomas-zikmunda.cz/
let SiteContentZikmunda = {
    currentRealEstateAgent: 'SiteContentZikmunda',
    name: 'Tomáš Zikmunda',
    type: 'REALITNÍ MAKLÉŘ'
}
// Radek Spáčil
// https://radek-spacil.cz/
let SiteContentSpacil = {
    currentRealEstateAgent: 'SiteContentSpacil',
    name: 'Radek Spáčil',
    type: 'REALITNÍ MAKLÉŘ'
}

export default SiteContentBerkova;